// Angular Imports
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

// Project Imports

import { SharedModule } from '@shared/shared.module';
import { AspenFooterComponent } from './components/aspen-footer.component';

@NgModule({
    declarations: [AspenFooterComponent],
    imports: [MatIconModule, SharedModule],
    exports: [AspenFooterComponent]
})
export class AspenFooterModule {}
