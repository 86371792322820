// Angular Imports
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

// Project Imports
import { SharedModule } from '@shared/shared.module';
import { UserDropdownContainerComponent } from './containers/user-dropdown-container.component';
import { AspenUserService } from './services/user.service';

@NgModule({
    declarations: [UserDropdownContainerComponent],
    imports: [
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        RouterModule,
        SharedModule
    ],
    providers: [AspenUserService],
    exports: [UserDropdownContainerComponent]
})
export class AspenUserModule {}
