// Third Party Imports
import { signalState, patchState } from '@ngrx/signals';

// Project Imports
import { ILocalStoragePersist } from './state-service.types';

/**
 * Base class for implementing stateful services using the Signal pattern for maintaining state.
 * Requires an interface <T> that describes the state model for the service. Takes an initialState
 * constructor arg provided by the derived class to initialize state values.
 *
 * Allows for state persistence by implementing the localStorageKey and localStorageVersion properties
 * on the derived class.
 */
export class SignalStateServiceBase<T extends object> {
    /**
     * Implement localStorageKey in your derived class to enable state persistence.
     * This key will be used to store and retrieve state from localStorage.
     */
    protected readonly localStorageKey: string;

    /**
     * Bump this number in your derived class when making breaking changes to
     * your state model. The service uses this number to determine if currently
     * stored state is compatible with the current state model.
     *
     * When in doubt, bump this number to force a reset of stored state.
     */
    protected readonly localStorageVersion: number = 1;

    /**
     * Stores a readonly version of the state that can be accessed by derived classes.
     * Use the setState method from derived classes to safely update state.
     */
    protected state: ReturnType<typeof signalState<T>>;

    /**
     * Constructor for the SignalStateServiceBase class. Initializes the state signal
     * with the provided initialState value.
     */
    constructor(initialState: T) {
        this.state = signalState<T>(initialState);
    }

    /**
     * Retrieves and deserializes the state object from localStorage if it exists.
     * If stored state is incompatible with the current localStorageVersion, it will be
     * removed.
     */
    public getStateFromLocalStorage(): Partial<T> {
        const stringifiedState = localStorage.getItem(this.localStorageKey);

        if (stringifiedState) {
            const storedValues = this.deserialize(stringifiedState);

            if (storedValues?.version === this.localStorageVersion) {
                return storedValues.data;
            } else {
                localStorage.removeItem(this.localStorageKey);
            }
        }

        return {};
    }

    /**
     * Updates state by merging the newState value into the current state.
     */
    protected setState(newState: Partial<T>): void {
        patchState(this.state, newState);

        if (this.localStorageKey) {
            this.copyStateToLocalStorage();
        }
    }

    /**
     * Deserializes a stringified state object. Override this method in your derived
     * class if you need to perform additional deserialization steps, such as converting
     * stringified dates back to Date objects.
     */
    protected deserialize(stringifiedState: string): ILocalStoragePersist<T> {
        return JSON.parse(stringifiedState);
    }

    /**
     * Serializes the state object. Override this method in your derived class if you need
     * to perform additional serialization steps, such as converting Date objects to strings.
     */
    protected serialize(currentState: T): ILocalStoragePersist<T> {
        return {
            version: this.localStorageVersion,
            data: currentState
        };
    }

    /**
     * Copies a serialized version of the current state to localStorage.
     */
    private copyStateToLocalStorage(): void {
        const currentState = this.state();

        localStorage.setItem(this.localStorageKey, JSON.stringify(this.serialize(currentState)));
    }
}
