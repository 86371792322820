// Angular Imports
import { Injectable } from '@angular/core';
import {
    HttpContextToken,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Router } from '@angular/router';

// Third Party Imports
import { Observable } from 'rxjs';

// Project Imports
import { AspenAuthService } from '@core/auth/aspen-auth.service';
import {
    catch400,
    catch401,
    catch403,
    catch422,
    catch500,
    catch503
} from '@core/operators/api-error.operators';
import { AspenToastService } from '@shared/aspen-toast/aspen-toast.service';

export const BYPASS_ERRORS = new HttpContextToken(() => false);

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
    constructor(
        private _aspenAuthService: AspenAuthService,
        private _router: Router,
        private aspenToastService: AspenToastService
    ) {}

    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (request.context.get(BYPASS_ERRORS) === true) {
            return next.handle(request);
        }
        return next.handle(request).pipe(
            catch400((error) => {
                this.aspenToastService.push({
                    type: 'error',
                    message: error.error.message || '400: Bad Request'
                });
            }),
            catch401((error) => {
                this._aspenAuthService.clearJwt();
                this._router.navigate(['/sign-out']);
            }),
            catch403((error) => {
                this._router.navigate(['error/403']);
            }),
            catch422((error) => {
                this.aspenToastService.push({
                    type: 'error',
                    message: error.error.message
                });
            }),
            catch500((error) => {
                this.aspenToastService.push({
                    type: 'error',
                    message:
                        'Something went wrong. Try again or contact an ASPEN admin for support.'
                });
            }),
            catch503((error) => {
                this.aspenToastService.push({
                    type: 'error',
                    message:
                        'Something went wrong. Try again or contact an ASPEN admin for support.'
                });
            })
        );
    }
}
