<button
    data-cy="userMenuCircle"
    mat-fab
    [matMenuTriggerFor]="userActions"
    class="aspen-user__button"
>
    <div class="aspen-user__initials">
        <h1>{{ userInitials$ | async }}</h1>
    </div>
</button>

<mat-menu xPosition="before" #userActions="matMenu">
    @for (navItem of userNavSignal(); track navItem) {
        <ng-container *showWithFeatureFlag="navItem.requiredFeatureFlags">
            <button
                mat-menu-item
                *showWithPermission="navItem.requiredPermissions"
                [routerLink]="navItem.link"
                [attr.data-cy]="navItem.dataCy"
                (click)="clearNavItem()"
            >
                <mat-icon [svgIcon]="navItem.icon"></mat-icon>
                <span>
                    <span>{{ navItem.label }}</span>
                </span>
            </button>
        </ng-container>
    }
</mat-menu>
