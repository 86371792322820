<div class="toast__container">
    @switch (toastMessage.type) { @case ('info') {
    <span class="toast__type">Info: </span>
    } @case ('success') {
    <span class="toast__type">Success: </span>
    } @case ('warning') {
    <span class="toast__type">Warning: </span>
    } @case ('error') {
    <span class="toast__type">Error: </span>
    } }

    <span class="toast__message">{{ toastMessage.message }} </span>
    @if ({ value: countLeft$ | async }; as countLeft) { @if (countLeft.value === 0) {
    <mat-icon (click)="onDismiss()" class="toast__icon" svgIcon="heroicons_solid:x"></mat-icon>
    } @if (countLeft.value > 0) {
    <mat-icon
        (click)="onDismiss()"
        class="toast__icon"
        svgIcon="heroicons_solid:arrow-right"
    ></mat-icon>
    } @if (countLeft.value > 0) {
    <span class="toast__counter">({{ countLeft.value }} More)</span>
    } }
</div>
