import { Component, Input, OnInit } from '@angular/core';
import { IStockInfo } from '../aspen-footer.types';

@Component({
    selector: 'aspen-footer',
    templateUrl: './aspen-footer.component.html',
    styleUrls: ['./aspen-footer.component.scss']
})
export class AspenFooterComponent implements OnInit {
    @Input() public stockInfo: IStockInfo;
    public currentYear: number = new Date().getFullYear();
    public arrowDirection: string;
    public arrowColor: string;
    public selectedQuote: string;
    public aspenTreat: boolean = false;

    public quotes: string[] = [
        'Get back to work!',
        'Pump those numbers up!',
        'Push yourself, before we do!',
        "Don't count days. Make days count!",
        "I have not failed. I've just found 10,000 ways that won't work.",
        "Don't skip leg day!",
        "I don't need quotes, I need coffee!",
        'Do or do not, there is no try.',
        "I'm not superstitious, but I am a little stitious.",
        "You can't fail if you never try.",
        "Coming Soon: Aspen NFTs. It's probably a good investment?",
        "It's not about how you fall down. It's about how you get up!",
        "You didn't come this far to only come this far.",
        "Great things don't come from comfort zones.",
        'BPA Free!',
        'Mostly harmless!',
        'New look, same great taste!',
        'Live, Laugh, Love.',
        'New and improved!',
        "Age is of no importance unless you're a cheese.",
        'Tip your developers!',
        'What do you get when you cross a joke, with a rhetorical question?',
        'Currency-Grade Data!',
        "Everyday I'm shufflin'",
        'The real Aspen was the friends we made along the way.',
        'Everything is figureoutable.',
        "How's your Aspen?",
        'What goes up, must come down.',
        'Listen to them, the children of the night. What music they make!'
    ];

    constructor() {}

    public ngOnInit(): void {
        if (this.stockInfo) {
            const isPositiveChange = this.stockInfo.change >= 0;
            this.arrowDirection = isPositiveChange ? 'up' : 'down';
            this.arrowColor = isPositiveChange ? 'green' : 'red';
            this.selectedQuote = this.quotes[Math.floor(Math.random() * this.quotes.length)];
        }
    }

    public aspenSpin(): void {
        this.aspenTreat = !this.aspenTreat;
    }
}
