// Angular Imports
import { Component, Signal } from '@angular/core';

// Third Party Imports
import { first, map, Observable } from 'rxjs';

// Project Imports
import { AspenUserService } from '../services/user.service';
import { AspenNavigationStateService } from '@core/navigation/aspen-navigation-state.service';
import { AspenNavItem } from '@core/navigation/aspen-navigation.module';

@Component({
    selector: 'user-dropdown-container',
    templateUrl: './user-dropdown-container.component.html',
    styleUrls: ['./user-dropdown-container.component.scss']
})
export class UserDropdownContainerComponent {
    public userNavSignal: Signal<Omit<AspenNavItem, 'parent'>[]> =
        this.navStateService.userNavConfig;

    public userInitials$: Observable<string> = this._aspenUserService.user$.pipe(
        first(),
        map((user) => {
            const firstInitial = user.firstName[0].toUpperCase();
            const lastInitial = user.lastName[0].toUpperCase();

            return `${firstInitial}${lastInitial}`;
        })
    );

    constructor(
        private readonly _aspenUserService: AspenUserService,
        public navStateService: AspenNavigationStateService
    ) {}

    public clearNavItem(): void {
        this.navStateService.toggleNav(false);
    }
}
