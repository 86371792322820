// Angular Imports
import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

// Third Party Imports
import { Observable } from 'rxjs';

// Project Imports
import { ENV_TOKEN } from '@core/environment/environment.provider';
import { IEnvironment } from '@core/environment/environment.types';

const USER_API_TOKEN = '@user-api';
const DATA_API_TOKEN = '@data-api';

@Injectable()
export class ApiUrlInterceptor implements HttpInterceptor {
    constructor(@Inject(ENV_TOKEN) private readonly environment: IEnvironment) {}

    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        let requestUrl = request.url;

        if (requestUrl.includes(USER_API_TOKEN)) {
            requestUrl = requestUrl.replace(USER_API_TOKEN, this.environment.userApiUrl);
        }

        if (requestUrl.includes(DATA_API_TOKEN)) {
            requestUrl = requestUrl.replace(DATA_API_TOKEN, this.environment.dataApiUrl);
        }

        const newRequest = request.clone({
            url: requestUrl
        });

        return next.handle(newRequest);
    }
}
