import { AspenNavigationModule, AspenNavItem } from '@core/navigation/aspen-navigation.module';
import { NgModule } from '@angular/core';

const navItems: AspenNavItem[] = [
    {
        id: 'pod-performance',
        label: 'Pod Performance',
        type: 'basic',
        link: '/pod-performance',
        requiredPermissions: ['inventory-performance.read'],
        requiredFeatureFlags: ['pod-building'],
        dataCy: 'sidebarItemPodPerformance',
        parent: 'publisher-performance',
        order: 0
    },
    {
        id: 'vizio-alerts',
        label: 'ASPEN Alerts',
        parent: 'publisher-performance',
        order: 10,
        type: 'basic',
        link: '/aspen-alerts',
        requiredPermissions: ['alerts.read', 'alerts.create'],
        dataCy: 'sidebarItemAspenAlerts'
    },
    {
        id: 'supply-analytics',
        label: 'Supply Analytics',
        parent: 'publisher-performance',
        order: 20,
        type: 'basic',
        link: '/dashboard',
        requiredPermissions: ['dashboards.read'],
        requiredFeatureFlags: ['supply-analytics'],
        dataCy: 'sidebarItemTvMetrics'
    },
    {
        id: 'pre-fetch-reporting',
        label: 'Pre-fetch Reporting',
        parent: 'publisher-performance',
        order: 30,
        type: 'basic',
        link: '/pre-fetch-reporting',
        requiredPermissions: [],
        requiredFeatureFlags: [],
        dataCy: 'sidebarItemPreFetchReporting'
    },
    {
        id: 'ad-validation-reporting',
        label: 'Ad Validation Reporting',
        parent: 'publisher-performance',
        order: 40,
        type: 'basic',
        link: '/ad-validation-reporting',
        requiredPermissions: [],
        requiredFeatureFlags: [],
        dataCy: 'sidebarItemAdValidationReporting'
    },
    {
        id: 'first-slot-performance',
        label: '1st Slot Performance',
        type: 'basic',
        link: '/1st-slot-performance',
        parent: 'publisher-performance',
        order: 50,
        dataCy: 'sidebarItemFirstSlotPerformance',
        requiredFeatureFlags: ['1st-slot-performance']
    }
];
@NgModule({
    imports: [AspenNavigationModule.forChild(navItems)]
})
export class AspenPublisherPerformanceNavigationModule {}
