//Angular Imports
import { Component } from '@angular/core';

// Project Imports
import { SharedModule } from '@shared/shared.module';
import { MatIconModule } from '@angular/material/icon';

@Component({
    standalone: true,
    selector: 'aspen-splash-page',
    templateUrl: './aspen-splash-page.html',
    styleUrls: ['./aspen-splash-page.scss'],
    imports: [SharedModule, MatIconModule]
})
export class AspenSplashPageComponent {
    constructor() {}
}
