import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AspenNavigationStateService } from '@core/navigation/aspen-navigation-state.service';
import { FeatureFlagsService } from '@modules/feature-flags/services/feature-flags.service';

export const maintenanceGuard: CanActivateFn = (route, state) => {
    const navStateService = inject(AspenNavigationStateService);
    const featureFlagService = inject(FeatureFlagsService);
    const router = inject(Router);
    featureFlagService.getAllAutoGenFlags().subscribe((flags) => navStateService.setFlags(flags));
    if (navStateService.getNav(state.url)?.maintenanceFlagBool) {
        router.navigate(['/error/maintenance']);
        return false;
    }
    return true;
};
