// Angular Imports
import { InjectionToken, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

// Project Imports
import { aspenNavStructure, AspenParentId } from './aspen-nav-structure';
import { AspenNavigationState } from './aspen-navigation-state.service';

export interface AspenParentNavItem {
    id: AspenParentId;
    label: string;
    icon: string;
    requiredPermissions?: string[];
    requiredFeatureFlags?: string[];
    dataCy?: string;
    hiddenWithFeatureFlags?: string[];
    link?: string;
}

export interface AspenNavItem {
    id: string;
    label: string;
    parent: AspenParentId;
    order: number;
    type: 'aside' | 'basic' | 'collapsable' | 'divider' | 'group' | 'spacer';
    exactMatch?: boolean;
    icon?: string;
    requiredPermissions?: string[];
    requiredFeatureFlags?: string[];
    dataCy?: string;
    hiddenWithFeatureFlags?: string[];
    link?: string;
}

export const ASPEN_NAV_STRUCTURE = new InjectionToken<AspenParentNavItem[]>('ASPEN_NAV_STRUCTURE');
export const ASPEN_NAV = new InjectionToken<AspenNavItem[][]>('ASPEN_NAV');
export const INIT_NAV_STATE = new InjectionToken<AspenNavigationState>('INIT_NAV_STATE');
export const USER_NAV = new InjectionToken<AspenNavItem[]>('USER_NAV_STATE');

@NgModule()
export class AspenNavigationModule {
    constructor(@Optional() @SkipSelf() parentModule?: AspenNavigationModule) {
        if (parentModule) {
            throw new Error(
                'AspenNavigationModule is already loaded. Import it in the AppModule only!'
            );
        }
    }

    // istanbul ignore next
    public static forRoot(rootNav?: AspenNavItem[]): ModuleWithProviders<AspenNavigationModule> {
        return {
            ngModule: AspenNavigationModule,
            providers: [
                { provide: ASPEN_NAV_STRUCTURE, useValue: aspenNavStructure },
                {
                    provide: INIT_NAV_STATE,
                    useFactory: provideInitialNavState,
                    deps: [ASPEN_NAV_STRUCTURE, ASPEN_NAV]
                }
            ]
        };
    }

    // istanbul ignore next
    public static forChild(childNav: AspenNavItem[]): ModuleWithProviders<AspenNavigationModule> {
        return {
            ngModule: AspenNavigationModule,
            providers: [{ provide: ASPEN_NAV, multi: true, useValue: childNav }]
        };
    }
}

export function provideInitialNavState(
    navStructure: AspenParentNavItem[],
    navItems: AspenNavItem[]
): AspenNavigationState {
    const navConfig = navStructure
        .filter((parent) => parent.id !== 'user')
        .map((parent) => {
            return {
                id: parent.id,
                label: parent.label,
                icon: parent.icon,
                requiredPermissions: parent.requiredPermissions,
                requiredFeatureFlags: parent.requiredFeatureFlags,
                dataCy: parent.dataCy,
                children: navItems
                    .flat()
                    .filter((item) => item.parent === parent.id && item.parent !== 'user')
                    .sort((a, b) => a.order - b.order)
                    .map((item) => {
                        return {
                            id: item.id,
                            label: item.label,
                            order: item.order,
                            type: item.type,
                            exactMatch: item.exactMatch,
                            icon: item.icon,
                            requiredPermissions: item.requiredPermissions,
                            requiredFeatureFlags: item.requiredFeatureFlags,
                            dataCy: item.dataCy,
                            hiddenWithFeatureFlags: item.hiddenWithFeatureFlags,
                            link: item.link,
                            parent: item.parent
                        };
                    })
            };
        });

    const userNavConfig = navItems
        .flat()
        .filter((item) => {
            return item.parent === 'user';
        })
        .map((item) => {
            return {
                id: item.id,
                label: item.label,
                order: item.order,
                type: item.type,
                exactMatch: item.exactMatch,
                icon: item.icon,
                requiredPermissions: item.requiredPermissions,
                requiredFeatureFlags: item.requiredFeatureFlags,
                dataCy: item.dataCy,
                hiddenWithFeatureFlags: item.hiddenWithFeatureFlags,
                link: item.link
            };
        })
        .sort((a, b) => a.order - b.order);

    return {
        opened: true,
        sideNavConfig: navConfig,
        userNavConfig: userNavConfig
    };
}
