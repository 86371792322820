// Angular Imports
import { Injectable } from '@angular/core';

// Third Party Imports
import { first, map, Observable } from 'rxjs';

// Project Imports
import { AspenAuthService } from '@core/auth/aspen-auth.service';

export interface AspenUser {
    id: number;
    fullName: string;
    firstName: string;
    lastName: string;
    email: string;
    roles: string[];
    permissions: string[];
    featureFlags: string[];
}

@Injectable({
    providedIn: 'root'
})
export class AspenUserService {
    /* istanbul ignore next */
    public user$: Observable<AspenUser> = this._aspenAuthService.jwt$.pipe(
        map((jwt) => {
            if (jwt) {
                const [, payload] = jwt.split('.');
                const decodedPayload = JSON.parse(atob(payload));
                return {
                    id: decodedPayload.user_id,
                    fullName: decodedPayload.name,
                    firstName: decodedPayload.first_name,
                    lastName: decodedPayload.last_name,
                    email: decodedPayload.email,
                    roles: decodedPayload.roles,
                    permissions: decodedPayload.permissions,
                    featureFlags: decodedPayload.feature_flags
                };
            } else {
                return null;
            }
        })
    );

    public userId$: Observable<number> = this.user$.pipe(
        first(),
        map((user) => user.id)
    );

    constructor(private _aspenAuthService: AspenAuthService) {}

    public userHasPermissions(permissions: string | string[]): Observable<boolean> {
        return this.user$.pipe(
            first(),
            map((user) => {
                if (!permissions || permissions.length === 0) {
                    return true;
                }

                if (Array.isArray(permissions)) {
                    return permissions.every((p) => user.permissions.includes(p));
                }

                return user.permissions.includes(permissions);
            })
        );
    }

    public userHasAnyPermissions(permissions: string | string[]): Observable<boolean> {
        return this.user$.pipe(
            first(),
            map((user) => {
                if (!permissions || !permissions.length) {
                    return true;
                }

                if (Array.isArray(permissions)) {
                    return permissions.some((p) => user.permissions.includes(p));
                }

                return user.permissions.includes(permissions);
            })
        );
    }

    public userHasRoles(): Observable<boolean> {
        return this.user$.pipe(
            map((user) => {
                return user?.roles?.length > 0;
            })
        );
    }

    public userHasFeatureFlags(
        featureFlags: string | string[],
        returnIfEmpty: boolean = true
    ): Observable<boolean> {
        return this.user$.pipe(
            map((user) => {
                if (!featureFlags || featureFlags.length === 0) {
                    return returnIfEmpty;
                }

                if (Array.isArray(featureFlags)) {
                    return featureFlags.every((p) => user.featureFlags.includes(p));
                }

                return user.featureFlags.includes(featureFlags);
            })
        );
    }

    public userHasAnyFeatureFlags(featureFlags: string | string[]): Observable<boolean> {
        return this.user$.pipe(
            map((user) => {
                if (!featureFlags || featureFlags.length === 0) {
                    return true;
                }

                if (Array.isArray(featureFlags)) {
                    return featureFlags.some((p) => user.featureFlags.includes(p));
                }

                return user.featureFlags.includes(featureFlags);
            })
        );
    }
}
