import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagShowDirective } from './directives/feature-flags-show.directive';
import { FeatureFlagShowEitherDirective } from './directives/feature-flags-show-either.directive';
import { FeatureFlagHideDirective } from './directives/feature-flags-hide.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PermissionShowDirective } from './directives/permission-show.directive';
import { PermissionHideDirective } from './directives/permission-hide.directive';
import { PermissionShowEitherDirective } from './directives/permission-show-either.directive';
import { PipesModule } from './pipes/pipes.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
    declarations: [
        FeatureFlagShowDirective,
        FeatureFlagShowEitherDirective,
        FeatureFlagHideDirective,
        PermissionShowDirective,
        PermissionHideDirective,
        PermissionShowEitherDirective
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PipesModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatSnackBarModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FeatureFlagShowDirective,
        FeatureFlagShowEitherDirective,
        FeatureFlagHideDirective,
        PermissionShowDirective,
        PermissionHideDirective,
        PermissionShowEitherDirective,
        PipesModule,
        MatButtonModule,
        MatIconModule
    ]
})
export class SharedModule {}
