// Angular Imports
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';

// Third Party Imports
import {
    catchError,
    concatMap,
    delay,
    EMPTY,
    mergeMap,
    Observable,
    of,
    OperatorFunction,
    throwError
} from 'rxjs';

/**
 * Interface describing a callback function that can be passed to one of the error handling operators.
 */
interface IHttpErrorCallback<T = never> {
    (error: HttpErrorResponse): void | Observable<T>;
}

/**
 * catchHttpStatus is an RxJS operator that can be used to catch any arbitrary HTTP status code
 * within an Observable pipeline, and define customized behavior via a passed callback function.
 *
 * Errors caught that are not HttpErrorResponses will be re-thrown and the provided callback
 * will not execute.
 */
export function catchHttpStatus<T, R = never>(
    statusCode: HttpStatusCode,
    callback: IHttpErrorCallback<R>
): OperatorFunction<T, T | R> {
    return (source$) =>
        source$.pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === statusCode) {
                    return callback(error) || EMPTY;
                } else {
                    return throwError(error);
                }
            })
        );
}

export function catch400<T, R = never>(callback: IHttpErrorCallback): OperatorFunction<T, T | R> {
    return (source$) => source$.pipe(catchHttpStatus<T, R>(HttpStatusCode.BadRequest, callback));
}

/**
 * Shortcut operator for catching 401 errors.
 */
export function catch401<T, R = never>(
    callback: IHttpErrorCallback<R>
): OperatorFunction<T, T | R> {
    return (source$) => source$.pipe(catchHttpStatus<T, R>(HttpStatusCode.Unauthorized, callback));
}

/**
 * Shortcut operator for catching 403 errors.
 */
export function catch403<T, R = never>(
    callback: IHttpErrorCallback<R>
): OperatorFunction<T, T | R> {
    return (source$) => source$.pipe(catchHttpStatus<T, R>(HttpStatusCode.Forbidden, callback));
}

/**
 * Shortcut operator for catching 404 errors.
 */
export function catch404<T, R = never>(
    callback: IHttpErrorCallback<R>
): OperatorFunction<T, T | R> {
    return (source$) => source$.pipe(catchHttpStatus<T, R>(HttpStatusCode.NotFound, callback));
}

/**
 * Shortcut operator for catching 422 errors.
 */
export function catch422<T, R = never>(
    callback: IHttpErrorCallback<R>
): OperatorFunction<T, T | R> {
    return (source$) =>
        source$.pipe(catchHttpStatus<T, R>(HttpStatusCode.UnprocessableEntity, callback));
}

/**
 * Shortcut operator for catching 500 errors.
 */
export function catch500<T, R = never>(
    callback: IHttpErrorCallback<R>
): OperatorFunction<T, T | R> {
    return (source$) =>
        source$.pipe(catchHttpStatus<T, R>(HttpStatusCode.InternalServerError, callback));
}

export function catch503<T, R = never>(
    callback: IHttpErrorCallback<R>
): OperatorFunction<T, T | R> {
    return (source$) =>
        source$.pipe(catchHttpStatus<T, R>(HttpStatusCode.ServiceUnavailable, callback));
}

/**
 * Easter-Egg Operator catching 418 errors.
 */
export function catchTeapot<T>(delayTime: number): OperatorFunction<T, string> {
    return (source$) =>
        source$.pipe(
            catchHttpStatus<T, string[]>(HttpStatusCode.ImATeapot, (error) => {
                const teapot = [
                    "🫖  I'm a little tea-pot,",
                    '🫖  Short and stout.',
                    '🫖  Here is my handle,',
                    '🫖  Here is my spout.',
                    '🫖  When I get all steamed up,',
                    '🫖  Hear me shout:',
                    '🫖  Tip me over,',
                    '🫖  And pour me out!'
                ];

                return of(teapot);
            }),
            mergeMap<string[], string[]>((teapot) => teapot),
            concatMap((teapot) => of(teapot).pipe(delay(delayTime)))
        );
}
