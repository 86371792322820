// Angular Imports
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

// Third Party Imports
import { Observable, of, switchMap, withLatestFrom } from 'rxjs';

// Project Imports
import { AspenAuthService } from '../aspen-auth.service';
import { AspenUserService } from '@shared/aspen-user/services/user.service';
import { ENV_TOKEN } from '@core/environment/environment.provider';
import { IEnvironment } from '@core/environment/environment.types';

const SSO_URL_RESPONSE_TYPE = 'code';
const SSO_URL_RESPONSE_MODE = 'query';

@Injectable({
    providedIn: 'root'
})
export class AspenAuthGuard {
    constructor(
        private authService: AspenAuthService,
        private aspenUserService: AspenUserService,
        @Inject(ENV_TOKEN) private env: IEnvironment
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        if (this.env.jwtOverride) {
            this.authService.setJwt(this.env.jwtOverride);
        }

        return this.authService.jwt$.pipe(
            withLatestFrom(this.aspenUserService.userHasRoles()),
            switchMap(([isAuthenticated, userHasRoles]) => {
                if (!isAuthenticated) {
                    window.location.href = this._getSSOUrl(state.url);

                    return of(false);
                } else if (isAuthenticated && !userHasRoles) {
                    window.location.href = '/error/403';

                    return of(false);
                }
                return of(true);
            })
        );
    }

    private _getSSOUrl(redirectUri: string): string {
        const ssoState = {
            redirectUri,
            envUrl: window.location.protocol + '//' + window.location.host + '/auth/response-oidc'
        };

        const ssoUrlQueryParams = [
            `client_id=${this.env.clientId}`,
            `redirect_uri=${this.env.redirectUri}`,
            `response_type=${SSO_URL_RESPONSE_TYPE}`,
            `response_mode=${SSO_URL_RESPONSE_MODE}`,
            `scope=${this.env.scope}`,
            `state=${JSON.stringify(ssoState)}`,
            `nonce=12345`
        ];

        return encodeURI(`${this.env.ssoUrl}?${ssoUrlQueryParams.join('&')}`);
    }
}
