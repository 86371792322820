// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';

// Third Party Imports
import { Observable, catchError, map, of } from 'rxjs';
import { BYPASS_ERRORS } from '@app/core/interceptors/api-error.interceptor';

export interface IWalmartStockHttpResponse {
    price: number;
    change: number;
    last_updated: string;
}

export interface IWalmartStockInfo {
    price: number;
    change: number;
    lastUpdated: string;
}

@Injectable({
    providedIn: 'root'
})
export class WalmartStockService {
    constructor(private readonly httpClient: HttpClient) {}
    public getStockInfo(): Observable<IWalmartStockInfo> {
        return this.httpClient
            .get<IWalmartStockHttpResponse>(`@user-api/v1/stock-price`, {
                context: new HttpContext().set(BYPASS_ERRORS, true)
            })
            .pipe(
                map((response) => {
                    return {
                        price: response.price,
                        change: response.change,
                        lastUpdated: response.last_updated
                    };
                }),
                catchError(() => {
                    return of({
                        price: 0,
                        change: 0,
                        lastUpdated: new Date().toISOString()
                    });
                })
            );
    }
}
