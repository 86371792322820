// Angular Imports
import { Injectable } from '@angular/core';

// Project Imports
import { StateServiceBase } from '@core/state-service/state-service.base';
import { IVizioStockInfo } from '@shared/services/vizio-stock.service';
import { Observable } from 'rxjs';

export interface IAppState {
    vizioStock?: IVizioStockInfo;
}

const initialState: IAppState = {
    vizioStock: null
};

@Injectable({
    providedIn: 'root'
})
export class AppStateService extends StateServiceBase<IAppState> {
    public vizioStock$: Observable<IVizioStockInfo> = this.select((state) => state.vizioStock);

    constructor() {
        super(initialState);
    }

    public setVizioStock(stockInfo: IVizioStockInfo): void {
        this.setState({
            vizioStock: stockInfo
        });
    }
}
