@if ((isLoading$ | async) === false) {
    <router-outlet></router-outlet>
}
@if (isLoading$ | async) {
    <aspen-splash-page class="aspen-splash-page"></aspen-splash-page>
}
@if (isSpooky$ | async) {
    <div class="ghost-container">
        <div class="ghost-wrapper">
            <img class="ghost" src="../../../../assets/images/easter-eggs/ghost-svg.svg" />
        </div>
    </div>
}
@if (isLocalPointedToProduction) {
    <div class="local-production-warning">
        <span>
            Your local environment is currently configured to point to non-local APIs. Tread
            carefully here. Update your environment.ts file to point back to local.
        </span>
    </div>
}
