// Angular Imports
import { Route } from '@angular/router';

// Project Imports
import { AspenAuthGuard } from '@core/auth/guards/aspen-auth.guard';
import { AspenSSOGuard } from '@core/auth/guards/aspen-sso.guard';
import { LayoutComponent } from '@layout/layout.component';
import { AppStateResolver } from '@app/app-state.resolver';
import { maintenanceGuard } from '@core/navigation/maintenanceGuard';

// @formatter:off
// tslint:disable:max-line-length
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Auth Not Required Routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'error',
                loadChildren: () => import('@modules/error/error.module').then((m) => m.ErrorModule)
            },
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('@modules/sign-out/sign-out.module').then((m) => m.SignOutModule)
            }
        ]
    },

    // Auth Routes
    {
        path: 'auth/response-oidc',
        canActivate: [AspenSSOGuard],
        component: LayoutComponent
    },

    // Vizio Routes
    {
        path: '',
        canActivate: [AspenAuthGuard, maintenanceGuard],
        component: LayoutComponent,
        data: {
            layout: 'vizio'
        },
        resolve: {
            appState: AppStateResolver
        },
        children: [
            {
                path: 'dashboard',
                loadChildren: () =>
                    import('@modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
            },
            {
                path: 'home-screen',
                loadChildren: () =>
                    import('@modules/home-screen/home-screen.module').then(
                        (m) => m.HomeScreenModule
                    )
            },
            {
                path: 'rf-planning',
                loadChildren: () =>
                    import('@modules/rf-planning/rf-planning.module').then(
                        (m) => m.RfPlanningModule
                    )
            },
            {
                path: 'reserved-capacity',
                loadChildren: () =>
                    import('@modules/reserved-capacity/reserved-capacity.module').then(
                        (m) => m.ReservedCapacityModule
                    )
            },
            {
                path: 'pending-reservations',
                loadChildren: () =>
                    import('@modules/reserved-capacity/pending-reservations.module').then(
                        (m) => m.PendingReservationsModule
                    )
            },
            {
                path: 'debug',
                loadChildren: () => import('@modules/debug/debug.module').then((m) => m.DebugModule)
            },
            {
                path: 'user-management',
                loadChildren: () =>
                    import('@modules/user-management/user-management.module').then(
                        (m) => m.UserManagementModule
                    )
            },
            {
                path: 'typography',
                loadChildren: () =>
                    import('@modules/typography/typography.module').then((m) => m.TypographyModule)
            },
            {
                path: 'examples',
                loadChildren: () =>
                    import('@modules/examples/examples.module').then((m) => m.ExamplesModule)
            },
            {
                path: 'home',
                loadChildren: () => import('@modules/home/home.module').then((m) => m.HomeModule)
            },
            {
                path: 'announcements-manager',
                loadChildren: () =>
                    import('@modules/announcements/announcements-manager.module').then(
                        (m) => m.AnnouncementsManagerModule
                    )
            },
            {
                path: 'milestone-manager',
                loadChildren: () =>
                    import('@modules/milestones/milestone-manager.module').then(
                        (m) => m.MilestoneManagerModule
                    )
            },
            {
                path: 'supply-config',
                loadChildren: () =>
                    import('@modules/onboarding/supply-config/supply-config.module').then(
                        (m) => m.SupplyConfigModule
                    )
            },
            {
                path: 'vail-ad-tag-requests',
                loadChildren: () =>
                    import(
                        '@modules/onboarding/vail-ad-tag-requests/vail-ad-tag-requests.module'
                    ).then((m) => m.VailAdTagRequestsModule)
            },
            {
                path: 'pacing-reporting',
                loadChildren: () =>
                    import('@modules/campaigns/pacing-reporting/pacing-reporting.module').then(
                        (m) => m.PacingReportingModule
                    )
            },
            {
                path: 'feature-flags',
                loadChildren: () =>
                    import('@modules/feature-flags/feature-flags.module').then(
                        (m) => m.FeatureFlagsModule
                    )
            },
            {
                path: 'pacing-admin',
                loadChildren: () =>
                    import('@modules/pacing-admin/pacing-admin.module').then(
                        (m) => m.PacingAdminModule
                    )
            },
            {
                path: 'pre-fetch-reporting',
                loadChildren: () =>
                    import('@modules/pre-fetch-reporting/pre-fetch-reporting.module').then(
                        (m) => m.PreFetchReportingModule
                    )
            },
            {
                path: 'ad-validation-reporting',
                loadChildren: () =>
                    import('@modules/ad-validation-reporting/ad-validation-reporting.module').then(
                        (m) => m.AdValidationReportingModule
                    )
            },
            {
                path: 'macro-manager',
                loadChildren: () =>
                    import('@modules/onboarding/macro-manager/macro-manager.module').then(
                        (m) => m.MacroManagerModule
                    )
            },
            {
                path: 'aspen-alerts',
                loadChildren: () =>
                    import('@modules/aspen-alerts/aspen-alerts.module').then(
                        (m) => m.AspenAlertsModule
                    )
            },
            {
                path: 'bug-report',
                loadChildren: () =>
                    import('@modules/bug-report/add-bug-report.module').then(
                        (m) => m.BugReportModule
                    )
            },
            {
                path: 'pod-performance',
                loadChildren: () =>
                    import('@app/modules/pod-building/pod-building.module').then(
                        (m) => m.PodBuildingModule
                    )
            },
            {
                path: 'bulk-update-tool',
                loadChildren: () =>
                    import('@app/modules/onboarding/bulk-update-tool/bulk-update-tool.module').then(
                        (m) => m.BulkUpdateToolModule
                    )
            },
            {
                path: 'vizio-dmp',
                loadChildren: () =>
                    import('@app/modules/audience/vizio-dmp/vizio-dmp.module').then(
                        (m) => m.VizioDmpModule
                    )
            },
            {
                path: 'supply-hub-future',
                loadChildren: () =>
                    import('@modules/supply-hub/supply-hub.module').then((m) => m.SupplyHubModule)
            },
            {
                path: 'supply-hub',
                loadChildren: () =>
                    import('@modules/supply-hub-legacy/supply-hub-legacy.module').then(
                        (m) => m.SupplyHubLegacyModule
                    )
            },
            {
                path: '1st-slot-performance',
                loadChildren: () =>
                    import(
                        '@app/modules/first-slot-performance/first-slot-performance.module'
                    ).then((m) => m.FirstSlotPerformanceModule)
            },
            {
                path: 'ad-traffic-qa',
                loadChildren: () =>
                    import('@modules/ad-traffic-qa/ad-traffic-qa.module').then(
                        (m) => m.AdTrafficQaModule
                    )
            }
        ]
    },

    // 404 Route -- This is a catch-all route and needs to be the last route in this list.
    { path: '**', redirectTo: '/error/404' }
];
