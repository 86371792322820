import { AspenNavigationModule, AspenNavItem } from '@core/navigation/aspen-navigation.module';
import { NgModule } from '@angular/core';

const navItems: AspenNavItem[] = [
    {
        id: 'announcements-manager',
        label: 'Announcements',
        type: 'basic',
        icon: 'heroicons_outline:bell',
        link: '/announcements-manager',
        requiredPermissions: ['users.read', 'announcements.create'],
        dataCy: 'userMenuItemAnnouncements',
        parent: 'user',
        order: 0
    },
    {
        id: 'feature-flags',
        label: 'Feature Flags',
        type: 'basic',
        icon: 'heroicons_outline:flag',
        link: '/feature-flags',
        requiredPermissions: ['users.read'],
        dataCy: 'userMenuItemFeatureFlags',
        parent: 'user',
        order: 10
    },
    {
        id: 'milestone-manager',
        label: 'Milestones',
        type: 'basic',
        icon: 'heroicons_outline:calendar',
        link: '/milestone-manager',
        requiredPermissions: ['users.read', 'announcements.create'],
        dataCy: 'userMenuItemMilestones',
        parent: 'user',
        order: 20
    },
    {
        id: 'user-management',
        label: 'Users',
        type: 'basic',
        icon: 'heroicons_outline:user-group',
        link: '/user-management',
        requiredPermissions: ['users.read'],
        dataCy: 'userMenuItemUsers',
        parent: 'user',
        order: 30
    },
    {
        id: 'bug-report',
        label: 'Bug Report',
        type: 'basic',
        icon: 'heroicons_outline:exclamation',
        link: '/bug-report',
        dataCy: 'userMenuItemBugReport',
        parent: 'user',
        order: 40
    },
    {
        id: 'sign-out',
        label: 'Sign Out',
        type: 'basic',
        icon: 'heroicons_outline:logout',
        link: '/sign-out',
        dataCy: 'userMenuItemSignOut',
        parent: 'user',
        order: 50
    }
];

@NgModule({
    imports: [AspenNavigationModule.forChild(navItems)]
})
export class UserNavigationModule {}
