// Angular Imports
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// Third Party Imports
import {
    BehaviorSubject,
    first,
    map,
    Observable,
    Subject,
    takeUntil,
    withLatestFrom,
    zip
} from 'rxjs';

// Project Imports
import { AspenToastComponent } from '@shared/aspen-toast/aspen-toast.component';

export type ToastType = 'info' | 'error' | 'success' | 'warning';

export interface IToastMessage {
    type: ToastType;
    message: string;
    duration?: number;
}

@Injectable({
    providedIn: 'root'
})
export class AspenToastService implements OnDestroy {
    private readonly messageCountSubject: BehaviorSubject<number> = new BehaviorSubject(0);
    public messageCount$: Observable<number> = this.messageCountSubject.asObservable();
    public countLeft$: Observable<number> = this.messageCount$.pipe(
        map((c) => (c > 0 ? c - 1 : 0))
    );

    private readonly messageQueueSubject: Subject<IToastMessage> = new Subject();
    public messageQueue$: Observable<IToastMessage> = this.messageQueueSubject.asObservable();

    private readonly nextMessageNotifySubject: Subject<void> = new Subject();
    public nextMessageNotify$: Observable<void> = this.nextMessageNotifySubject.asObservable();

    private readonly unsubscribeAllSubject: Subject<void> = new Subject();
    public unsubscribeAll$: Observable<void> = this.unsubscribeAllSubject.asObservable();

    public messageEmitter$: Observable<IToastMessage> = zip(
        this.messageQueue$,
        this.nextMessageNotify$
    ).pipe(
        takeUntil(this.unsubscribeAll$),
        map(([nextMessage]: [IToastMessage, void]) => nextMessage)
    );

    constructor(private readonly matSnackBar: MatSnackBar) {
        this.messageEmitter$.subscribe((nextMessage) => {
            const snackbarRef = this.matSnackBar.openFromComponent(AspenToastComponent, {
                data: nextMessage,
                panelClass: ['max-w-max', `toast-${nextMessage.type}`],
                verticalPosition: 'top',
                duration: nextMessage.duration || 10000
            });

            snackbarRef
                .afterDismissed()
                .pipe(first(), withLatestFrom(this.messageCount$))
                .subscribe(([dismissEvent, messageCount]) => {
                    if (messageCount > 0) {
                        this.messageCountSubject.next(messageCount - 1);
                        this.nextMessageNotifySubject.next();
                    }
                });
        });
    }

    public push(toastMessage: IToastMessage): void {
        this.messageCount$.pipe(first()).subscribe((messageCount) => {
            if (messageCount === 0) {
                this.nextMessageNotifySubject.next();
            }

            this.messageQueueSubject.next(toastMessage);
            this.messageCountSubject.next(messageCount + 1);
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAllSubject.next();
    }
}
