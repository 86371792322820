// Angular Imports
import { InjectionToken, Provider } from '@angular/core';

// Project Imports
import { IEnvironment } from './environment.types';
import { environment } from '@environments/environment';

/**
 * The following provides a custom InjectionToken for using environment settings within the
 * Angular DI system. This allows for easier mocking of environments within unit tests and
 * prevents potential mistaken imports direct from environment-specific files.
 *
 * Example Usage:
 *
 *   class MyService {
 *      constructor(
 *        @Inject(ENV_TOKEN) env : IEnvironment
 *      ) {
 *        console.log('Are we in production?', env.production);
 *      }
 *   }
 *
 */
export const ENV_TOKEN: InjectionToken<IEnvironment> = new InjectionToken('ENV_TOKEN');

export const ENV_PROVIDER: Provider = {
    provide: ENV_TOKEN,
    useValue: environment
};
