import { AspenNavigationModule, AspenNavItem } from '@core/navigation/aspen-navigation.module';
import { NgModule } from '@angular/core';

const navItems: AspenNavItem[] = [
    {
        id: 'ad-traffic-qa',
        label: 'Ad Traffic QA',
        parent: 'ad-monitoring',
        order: 10,
        type: 'basic',
        link: '/ad-traffic-qa',
        dataCy: 'sidebarItemAdTrafficQa'
    }
];

@NgModule({
    imports: [AspenNavigationModule.forChild(navItems)]
})
export class AdMonitoringNavigationModule {}
