// Angular Imports
import { DestroyRef, Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// Third Party Imports
// Project Imports
import { AspenUserService } from '@shared/aspen-user/services/user.service';

/**
 * This structural directive can be used to show elements conditionally if a user has
 * a certain set of permissions.
 *
 * Examples:
 *
 * <!-- This div only renders if the user has the 'user.create' permission -->
 * <div *showWithPermission="'user.create'"></div>
 *
 * <!-- This div only renders if the user has both the 'user.create' and 'user.delete' permissions -->
 * <div *showWithPermission="['user.create', 'user.delete']"></div>
 */
@Directive({
    selector: '[showWithEitherPermission]'
})
export class PermissionShowEitherDirective {
    private _hasView: boolean = false;

    constructor(
        private readonly _templateRef: TemplateRef<any>,
        private readonly _viewContainerRef: ViewContainerRef,
        private readonly _aspenUserService: AspenUserService,
        @Inject(DestroyRef) private readonly destroyRef: DestroyRef
    ) {}

    @Input() set showWithEitherPermission(permissions: string | string[]) {
        if (permissions && permissions.length > 0) {
            this._aspenUserService
                .userHasAnyPermissions(permissions)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (hasPermission) => {
                        if (hasPermission && !this._hasView) {
                            this._viewContainerRef.createEmbeddedView(this._templateRef);
                            this._hasView = true;
                        } else if (!hasPermission && this._hasView) {
                            this._viewContainerRef.clear();
                            this._hasView = false;
                        }
                    }
                });
        } else {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
            this._hasView = true;
        }
    }
}
