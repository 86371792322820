// Angular Imports
import { DestroyRef, Directive, Inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// Third Party Imports
// Project Imports
import { AspenUserService } from '@shared/aspen-user/services/user.service';

@Directive({
    selector: '[showWithEitherFeatureFlag]'
})
export class FeatureFlagShowEitherDirective {
    private _hasView: boolean = false;

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private _aspenUserService: AspenUserService,
        @Inject(DestroyRef) private destroyRef: DestroyRef
    ) {}

    @Input() set showWithEitherFeatureFlag(featureFlags: string | string[]) {
        if (featureFlags && featureFlags.length > 0) {
            this._aspenUserService
                .userHasAnyFeatureFlags(featureFlags)
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe({
                    next: (hasFeatureFlag) => {
                        if (hasFeatureFlag && !this._hasView) {
                            this._viewContainerRef.createEmbeddedView(this._templateRef);
                            this._hasView = true;
                        } else if (!hasFeatureFlag && this._hasView) {
                            this._viewContainerRef.clear();
                            this._hasView = false;
                        }
                    }
                });
        } else {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
            this._hasView = true;
        }
    }
}
