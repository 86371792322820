// Project Imports
import { AspenParentNavItem } from './aspen-navigation.module';

export type AspenParentId =
    | 'publisher-performance'
    | 'planning'
    | 'campaigns'
    | 'vizio-dmp'
    | 'publisher-tools'
    | 'ad-monitoring'
    | 'dev-tools'
    | 'user';

export const aspenNavStructure: AspenParentNavItem[] = [
    {
        id: 'publisher-performance',
        label: 'Publisher Performance',
        icon: 'heroicons_outline:chart-bar',
        requiredPermissions: ['dashboards.read'],
        dataCy: 'sidebarGroupBusiness'
    },
    {
        id: 'planning',
        label: 'Planning',
        icon: 'heroicons_outline:clipboard-check',
        requiredPermissions: ['reservations.read', 'user-configs.read'],
        dataCy: 'sidebarGroupPlanning'
    },
    // With Pacing Reporting hidden as a feature, we can also hide the parent Campaigns group for the time being.
    // {
    //     id: 'campaigns',
    //     label: 'Campaigns',
    //     icon: 'heroicons_outline:speakerphone',
    //     dataCy: 'sidebarGroupCampaigns',
    //     requiredPermissions: ['campaigns.read']
    // },
    {
        id: 'vizio-dmp',
        label: 'VIZIO DMP',
        icon: 'heroicons_outline:user-group',
        dataCy: 'sidebarGroupVizioDmp',
        requiredPermissions: ['segments.user', 'segments.admin']
    },
    {
        id: 'publisher-tools',
        label: 'Publisher Tools',
        icon: 'heroicons_outline:presentation-chart-line',
        dataCy: 'sidebarGroupOnboarding',
        requiredPermissions: ['onboarding-tools.read']
    },
    {
        id: 'ad-monitoring',
        label: 'Ad Monitoring',
        icon: 'heroicons_outline:eye',
        dataCy: 'sidebarGroupAdMonitoring'
    },
    {
        id: 'dev-tools',
        label: 'Developer Tools',
        icon: 'heroicons_outline:code',
        dataCy: 'sidebarGroupDeveloper',
        requiredPermissions: ['devtools.read']
    }
];
