import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AspenNavigationStateService } from '@core/navigation/aspen-navigation-state.service';
import { AspenNavItem } from '@core/navigation/aspen-navigation.module';

@Directive({
    selector: '[autoGenHideWithFeatureFlag]',
    standalone: true
})
export class AutoGenHideWithFeatureFlagDirective {
    private _hasView: boolean = true;
    constructor(
        private readonly _templateRef: TemplateRef<any>,
        private readonly _viewContainerRef: ViewContainerRef,
        private readonly aspenNavigationStateService: AspenNavigationStateService
    ) {}

    @Input() set autoGenHideWithFeatureFlag(item: AspenNavItem) {
        if (this.aspenNavigationStateService.getNav(item.link)?.disabledFlagBool && this._hasView) {
            this._viewContainerRef.clear();
            this._hasView = false;
        } else {
            this._viewContainerRef.createEmbeddedView(this._templateRef);
            this._hasView = true;
        }
    }
}
