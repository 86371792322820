<section data-cy="stockFooter" class="footer__wrapper">
    @if (stockInfo) {
        <article class="footer__stock-wrapper">
            <div class="footer__stock-container" *showWithPermission="'expanded-footer.view'">
                VZIO Price: ${{ stockInfo.price | number: '1.2-2' }}
                <mat-icon
                    class="footer__arrow--{{ arrowColor }}"
                    svgIcon="heroicons_solid:arrow-sm-{{ arrowDirection }}"
                ></mat-icon>
                Updated: {{ stockInfo.lastUpdated | date: 'MM/dd' }} | {{ selectedQuote }}
                <mat-icon
                    class="footer__aspen-icon"
                    [ngClass]="aspenTreat ? 'footer__aspen-icon--rotate' : ''"
                    svgIcon="vizio:aspen-butt"
                    (click)="aspenSpin()"
                ></mat-icon>
            </div>
        </article>
    }
    <article class="footer__copyright-wrapper">Copyright &#169; Vizio {{ currentYear }}</article>
</section>
