// Angular Imports
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

// Project Imports
import { AspenAuthService } from './aspen-auth.service';

@NgModule({
    imports: [HttpClientModule],
    providers: [AspenAuthService]
})
export class AuthModule {}
