// Angular Imports
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

// Third Party Imports
import { first } from 'rxjs';

// Project Imports
import { AspenUserService } from '@shared/aspen-user/services/user.service';

/**
 * This structural directive can be used to show elements conditionally if a user has
 * a certain set of permissions.
 *
 * Examples:
 *
 * <!-- This div only renders if the user has the 'user.create' permission -->
 * <div *showWithPermission="'user.create'"></div>
 *
 * <!-- This div only renders if the user has both the 'user.create' and 'user.delete' permissions -->
 * <div *showWithPermission="['user.create', 'user.delete']"></div>
 */
@Directive({
    selector: '[showWithPermission]'
})
export class PermissionShowDirective {
    private _hasView: boolean = false;

    constructor(
        private readonly _templateRef: TemplateRef<any>,
        private readonly _viewContainerRef: ViewContainerRef,
        private readonly _aspenUserService: AspenUserService
    ) {}

    @Input() set showWithPermission(permissions: string | string[]) {
        this._aspenUserService
            .userHasPermissions(permissions)
            .pipe(first())
            .subscribe({
                next: (hasPermission) => {
                    if (hasPermission && !this._hasView) {
                        this._viewContainerRef.createEmbeddedView(this._templateRef);
                        this._hasView = true;
                    } else if (!hasPermission && this._hasView) {
                        this._viewContainerRef.clear();
                        this._hasView = false;
                    }
                }
            });
    }
}
