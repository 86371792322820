// Angular Imports
import { NgModule, Optional, SkipSelf } from '@angular/core';

// Project Imports
import { AuthModule } from '@core/auth/auth.module';
import { IconsModule } from '@core/icons/icons.module';
import { ENV_PROVIDER } from '@core/environment/environment.provider';

@NgModule({
    imports: [AuthModule, IconsModule],
    providers: [ENV_PROVIDER]
})
export class CoreModule {
    /**
     * Constructor
     */
    constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
        // Do not allow multiple injections
        if (parentModule) {
            throw new Error(
                'CoreModule has already been loaded. Import this module in the AppModule only.'
            );
        }
    }
}
