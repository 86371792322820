// Third Party Imports
import * as _ from 'lodash';
import { BehaviorSubject, distinctUntilChanged, map, Observable } from 'rxjs';

// Project Imports
import { ILocalStoragePersist } from './state-service.types';

/**
 * @deprecated Please use SignalStateService
 *
 * Base class for implementing stateful services using the BehaviorSubject / Observable
 * pattern for maintaining state. Requires an interface <T> that describes the state
 * model for the service. Takes an initialState constructor arg to initialize state values.
 */
export class StateServiceBase<T> {
    private stateSubject: BehaviorSubject<T>;

    public state$: Observable<T>;

    protected readonly localStorageKey: string;

    /**
     * Bump this number when making breaking changes to localStorage state persistence.
     */
    protected readonly localStorageVersion: number = 1;

    constructor(initialState: T) {
        this.stateSubject = new BehaviorSubject<T>(initialState);
        this.state$ = this.stateSubject.asObservable();
    }

    /**
     * Allows for slicing up the state model into smaller, more digestable, pieces, or
     * creating new values derived from current state. Takes a function argument
     * that recieves the current state value as a parameter and returns any new value.
     *
     * Use of the distinctUntilChanged() operator ensures that the Observables created
     * with this method only emit when their value has changed. This will help prevent
     * subscriptions from firing uneccessarily when unrelated state values get updated.
     */
    protected select<K>(mapFn: (state: T)=> K): Observable<K> {
        return this.state$.pipe(
            map((state: T) => mapFn(state)),
            distinctUntilChanged((prev, curr) => _.isEqual(prev, curr))
        );
    }

    /**
     * Updates state by merging the newState value into the current state.
     */
    protected setState(newState: Partial<T>): void {
        const currentState = this.stateSubject.getValue();

        // We don't want to merge array values. Instead we want to just replace them
        // with the new value.
        const mergeReplaceArrays = (objValue, srcValue) => {
            if (_.isArray(objValue)) {
                return srcValue;
            }
        };

        this.stateSubject.next(_.mergeWith({}, currentState, newState, mergeReplaceArrays));

        if (this.localStorageKey) {
            this.copyStateToLocalStorage();
        }
    }

    /**
     * Gets the current value of a certain state key. Allows the implementing class
     * to read values from state without requiring a subscription to the state$ Observable.
     * Useful when you need to construct a new state value that is dependent on existing
     * state values.
     *
     * !!WARNING!! This will eventually be replaced with a proper RxJS solution that doesn't
     * break the Observable stream. In the meantime, use carefully and never expose these
     * values outside the implementing service.
     */
    protected getStateValue<K extends keyof T>(key: K): T[K] {
        const currentState = this.stateSubject.getValue();

        return currentState[key];
    }

    protected copyStateToLocalStorage(): void {
        const currentState = this.stateSubject.getValue();

        localStorage.setItem(this.localStorageKey, this.serialize(currentState));
    }

    /* istanbul ignore next */
    public retrieveStateFromLocalStorage(): void {
        const stringifiedState = localStorage.getItem(this.localStorageKey);

        if (stringifiedState) {
            const storedValues = this.deserialize(stringifiedState);

            if (storedValues?.version === this.localStorageVersion) {
                this.setState(storedValues.data);
            } else {
                localStorage.removeItem(this.localStorageKey);
            }
        }
    }

    /* istanbul ignore next */
    protected deserialize(stringifiedState: string): ILocalStoragePersist<T> {
        return JSON.parse(stringifiedState);
    }

    protected serialize(parsedState: T): string {
        const persistWrap: ILocalStoragePersist<T> = {
            version: this.localStorageVersion,
            data: parsedState
        };

        return JSON.stringify(persistWrap);
    }
}
