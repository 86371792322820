<aspen-side-nav></aspen-side-nav>
<!-- Wrapper -->
<div class="vizio__wrapper">
    <!-- Header -->
    <div class="vizio__menu-wrapper">
        <button type="button" (click)="clickMenu()" class="vizio__menu" mat-button tabindex="0">
            <mat-icon [svgIcon]="'heroicons_outline:menu'" class="vizio__menu-icon"></mat-icon>
        </button>

        <!-- Components -->
        <div class="vizio__component-wrapper">
            <!-- Vizio branding icon -->
            <img
                class="vizio__logo"
                src="../../../../assets/images/logo/VIZIO_LOGO_WHITE.png"
                routerLink="/home"
                alt="Vizio logo linking to the home page"
            />
            @if ((isSpooky$ | async) === false) {
                <mat-icon
                    class="vizio__aspen-icon"
                    [inline]="true"
                    svgIcon="vizio:aspen-filled"
                ></mat-icon>
            }
            @if (isSpooky$ | async) {
                <mat-icon
                    class="vizio__spooky-icon"
                    [inline]="true"
                    svgIcon="vizio:aspen-pumpkin"
                ></mat-icon>
            }
            <h1 class="vizio__aspen-title">ASPEN</h1>
            <!-- HEADER COMPONENTS GO HERE -->
            <user-dropdown-container></user-dropdown-container>
        </div>
    </div>

    @if (pageTitle) {
        <div class="vizio__page-title-wrapper">
            <h1 data-cy="pageHeaderTitle" class="vizio__page-title">
                {{ pageTitle }}
            </h1>
        </div>
    }

    <!-- Content -->
    <div class="vizio__content-wrapper">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
    Otherwise, layout changes won't be registered and the view won't be updated! -->
        @if (true) {
            <router-outlet></router-outlet>
        }
    </div>

    <!-- Aspen Footer -->
    <aspen-footer [stockInfo]="vizioStock$ | async"></aspen-footer>
</div>
