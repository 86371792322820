import { AspenNavigationModule, AspenNavItem } from '@core/navigation/aspen-navigation.module';
import { NgModule } from '@angular/core';

const navItems: AspenNavItem[] = [
    ,
    {
        id: 'rf-planning',
        label: 'R/F Planning',
        parent: 'planning',
        order: 10,
        type: 'basic',
        link: '/rf-planning',
        dataCy: 'sidebarItemRfPlanning',
        requiredPermissions: ['user-configs.read']
    },
    {
        id: 'forecast-impressions',
        parent: 'planning',
        order: 20,
        label: 'Impressions & Forecast',
        type: 'basic',
        exactMatch: true,
        link: '/home-screen/forecast-impressions',
        requiredPermissions: ['reservations.read'],
        dataCy: 'sidebarItemImpressionsForecast'
    },
    {
        id: 'reserved-capacity',
        label: 'Reserved Capacity',
        parent: 'planning',
        order: 30,
        type: 'basic',
        link: '/reserved-capacity',
        dataCy: 'sidebarItemReservedCapacity',
        requiredPermissions: ['reservations.read']
    },
    {
        id: 'pending-reservations',
        label: 'Pending Reservations',
        parent: 'planning',
        order: 40,
        type: 'basic',
        link: '/pending-reservations',
        dataCy: 'sidebarItemPendingReservations',
        requiredPermissions: ['reservations.read']
    }
];

@NgModule({
    imports: [AspenNavigationModule.forChild(navItems)]
})
export class PlanningNavigationModule {}
