// Angular Imports
import { Inject, Injectable } from '@angular/core';
import {
    HttpContextToken,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';

// Third Party Imports
import { first, map, Observable, switchMap } from 'rxjs';

// Project Imports
import { AspenAuthService } from '@core/auth/aspen-auth.service';
import { IEnvironment } from '@app/core/environment/environment.types';
import { ENV_TOKEN } from '@app/core/environment/environment.provider';

/**
 * Token used to bypass authentication for certain requests. Should be used when
 * interacting with APIs that we don't control and don't want to send our JWT
 * tokens to.
 */
export const BYPASS_AUTH = new HttpContextToken(() => false);

@Injectable()
export class ApiHeadersInterceptor implements HttpInterceptor {
    constructor(
        private readonly _aspenAuthService: AspenAuthService,
        @Inject(ENV_TOKEN) private readonly _environment: IEnvironment
    ) {}

    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (request.context.get(BYPASS_AUTH) === true) {
            return next.handle(request);
        }

        const isAspenRequest =
            request.url.includes(this._environment.userApiUrl) ||
            request.url.includes(this._environment.dataApiUrl);

        const isMeadRequest = request.url.includes(this._environment.meadUrl);

        return this._aspenAuthService.jwt$.pipe(
            first(),
            map((aspenJwtValue) => {
                if (isAspenRequest) {
                    return aspenJwtValue;
                } else if (isMeadRequest) {
                    return this._environment.meadToken;
                } else {
                    return null;
                }
            }),
            switchMap((jwtValue) => {
                if (jwtValue) {
                    const newRequest = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${jwtValue}`
                        }
                    });
                    return next.handle(newRequest);
                } else {
                    return next.handle(request);
                }
            })
        );
    }
}
