import { AspenNavigationModule, AspenNavItem } from '@core/navigation/aspen-navigation.module';
import { NgModule } from '@angular/core';

/**
 * debug
 * typography
 * examples
 */
const navItems: AspenNavItem[] = [
    {
        id: 'debug',
        label: 'Debug',
        parent: 'dev-tools',
        order: 10,
        type: 'basic',
        link: '/debug',
        dataCy: 'sidebarItemDebug',
        requiredPermissions: ['devtools.read']
    },
    {
        id: 'typography',
        label: 'Typography',
        parent: 'dev-tools',
        order: 20,
        type: 'basic',
        link: '/typography',
        dataCy: 'sidebarItemTypography',
        requiredPermissions: ['devtools.read']
    },
    {
        id: 'examples',
        label: 'Examples',
        parent: 'dev-tools',
        order: 30,
        type: 'basic',
        link: '/examples',
        dataCy: 'sidebarItemExamples',
        requiredPermissions: ['devtools.read']
    }
];

@NgModule({
    imports: [AspenNavigationModule.forChild(navItems)]
})
export class DeveloperToolsNavigationModule {}
