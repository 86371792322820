// Angular Imports
import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';

// Third Party Imports
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// Project Imports
import { AspenAuthService } from '@core/auth/aspen-auth.service';

@Injectable()
export class ApiJwtRefreshInterceptor implements HttpInterceptor {
    constructor(private _aspenAuthService: AspenAuthService, private _router: Router) {}

    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            tap((event: HttpResponse<unknown>) => {
                if (event instanceof HttpResponse && event.headers.get('x-token') !== null) {
                    if (event.headers.get('x-token') === 'revoked') {
                        this._aspenAuthService.clearJwt();
                        this._router.navigate(['/sign-out']);
                    } else {
                        this._aspenAuthService.setJwt(event.headers.get('x-token'));
                    }
                }
            })
        );
    }
}
