// Angular Imports
import { AfterViewInit, Component, effect, ElementRef, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// Third Party Imports
import { animationFrames, distinct, endWith, map, takeWhile } from 'rxjs';

// Project Imports
import { SharedModule } from '@shared/shared.module';

const NUM_SNOWFLAKES = 50;

@Component({
    standalone: true,
    selector: 'ee-winter-wonderland',
    templateUrl: './winter-wonderland.component.html',
    styleUrls: ['./winter-wonderland.component.scss'],
    imports: [SharedModule]
})
export class WinterWonderlandComponent implements AfterViewInit {
    private readonly audioNocturneEl = viewChild<ElementRef>('audioNocturne');
    private readonly audioSnowmanLowEl = viewChild<ElementRef>('audioSnowmanLow');
    private readonly audioSnowmanMidEl = viewChild<ElementRef>('audioSnowmanMid');
    private readonly audioSnowmanHighEl = viewChild<ElementRef>('audioSnowmanHigh');
    private readonly audioSnowmanFinalEl = viewChild<ElementRef>('audioSnowmanFinal');

    protected readonly audioEffect = effect(() => {
        this.audioNocturneEl().nativeElement.volume = 0.1;
        this.audioSnowmanLowEl().nativeElement.volume = 0.4;
        this.audioSnowmanMidEl().nativeElement.volume = 0.4;
        this.audioSnowmanHighEl().nativeElement.volume = 0.4;
        this.audioSnowmanFinalEl().nativeElement.volume = 0.4;
    });

    public numSnowflakes = new Array(NUM_SNOWFLAKES).fill(0);

    public snowmanAnimation$ = animationFrames().pipe(
        map(({ elapsed }) => Math.floor(elapsed / 1000)),
        takeWhile((seconds) => seconds < 60),
        takeUntilDestroyed(),
        endWith(60),
        distinct()
    );

    public snowmanClass = 'snowman';

    public ngAfterViewInit(): void {
        this.snowmanAnimation$.subscribe((seconds) => {
            if (seconds >= 33 && seconds < 34) {
                this.audioSnowmanLowEl().nativeElement.play();
                this.snowmanClass = 'snowman snowman-frame-1';
            } else if (seconds >= 34 && seconds < 35) {
                this.audioSnowmanMidEl().nativeElement.play();
                this.snowmanClass = 'snowman snowman-frame-2';
            } else if (seconds >= 35 && seconds < 36) {
                this.audioSnowmanHighEl().nativeElement.play();
                this.snowmanClass = 'snowman snowman-frame-3';
            } else if (seconds >= 36 && seconds < 37) {
                this.audioSnowmanFinalEl().nativeElement.play();
                this.snowmanClass = 'snowman snowman-frame-4';
            }
        });
    }
}
