// Angular Imports
import { Injectable } from '@angular/core';
import {
    HttpContextToken,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';

// Third Party Imports
import { first, Observable, switchMap } from 'rxjs';

// Project Imports
import { AspenAuthService } from '@core/auth/aspen-auth.service';

/**
 * Token used to bypass authentication for certain requests. Should be used when
 * interacting with APIs that we don't control and don't want to send our JWT
 * tokens to.
 */
export const BYPASS_AUTH = new HttpContextToken(() => false);

@Injectable()
export class ApiHeadersInterceptor implements HttpInterceptor {
    constructor(private _aspenAuthService: AspenAuthService) {}

    public intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (request.context.get(BYPASS_AUTH) === true) {
            return next.handle(request);
        }

        return this._aspenAuthService.jwt$.pipe(
            first(),
            switchMap((jwtValue) => {
                if (jwtValue) {
                    const newRequest = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${jwtValue}`
                        }
                    });
                    return next.handle(newRequest);
                } else {
                    return next.handle(request);
                }
            })
        );
    }
}
