// Angular Imports
import { Injectable, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// Third Party Imports
import { filter } from 'rxjs';

// Project Imports
import { IEnvironment } from '@core/environment/environment.types';
import { ENV_TOKEN } from '@core/environment/environment.provider';

@Injectable({
    providedIn: 'root'
})
export class GoogleAnalyticsService {
    constructor(
        private readonly router: Router,
        @Inject(ENV_TOKEN) private readonly environment: IEnvironment
    ) {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe((event) => {
                gtag('js', new Date());
                gtag('config', this.environment.googleAnalyticsId);
            });
    }

    public init() {
        const script = document.createElement('script');

        script.async = true;
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.environment.googleAnalyticsId}`;
        document.getElementsByTagName('head')[0].appendChild(script);

        const gTagScript = document.createElement('script');
        const gTagBody = document.createTextNode(`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
        `);

        gTagScript.appendChild(gTagBody);

        document.body.appendChild(gTagScript);
    }
}
