// Angular Imports
import { computed, Inject, Injectable } from '@angular/core';

// Project Imports
import { SignalStateServiceBase } from '@core/state-service/signal-state-service.base';
import { AspenNavItem, INIT_NAV_STATE } from './aspen-navigation.module';
import { AspenParentId } from '@core/navigation/aspen-nav-structure';

export interface NavigationConfig {
    id: string;
    label: string;
    icon: string;
    requiredPermissions?: string[];
    requiredFeatureFlags?: string[];
    dataCy?: string;
    hiddenWithFeatureFlags?: string[];
    link?: string;
    children: AspenNavItem[];
}

export interface AspenNavigationState {
    sideNavConfig: NavigationConfig[];
    opened: boolean;
    userNavConfig: Omit<AspenNavItem, 'parent'>[];
    navigationFlags?: string[];
}

export interface AspenNavigationFlags {
    id: string;
    label: string;
    link: string;
    disabledFlag: string;
    disabledFlagBool?: boolean;
    maintenanceFlag: string;
    maintenanceFlagBool?: boolean;
    parentId?: AspenParentId;
}

@Injectable({
    providedIn: 'root'
})
export class AspenNavigationStateService extends SignalStateServiceBase<AspenNavigationState> {
    public navConfig = this.state.sideNavConfig;
    public userNavConfig = this.state.userNavConfig;
    public opened = this.state.opened;
    public navFlags = computed<AspenNavigationFlags[]>(() => {
        const flags = this.state().navigationFlags;
        return this.navConfig()
            .map((navGroup) => navGroup.children)
            .flat()
            .map((navItem) => ({
                id: navItem.id,
                label: navItem.label,
                link: navItem.link,
                disabledFlag: `${navItem.id}--nav-disabled`,
                maintenanceFlag: `${navItem.id}--maintenance`,
                disabledFlagBool: flags?.includes(`${navItem.id}--nav-disabled`),
                maintenanceFlagBool: flags?.includes(`${navItem.id}--maintenance`),
                parentId: navItem.parent
            }));
    });

    constructor(@Inject(INIT_NAV_STATE) initialState: AspenNavigationState) {
        super(initialState);
    }

    public getNav(url: string) {
        return this.navFlags().find((nav) => nav.link === url || url.includes(nav.parentId));
    }

    public toggleNav(open: boolean = null) {
        this.setState({ opened: open === null ? !this.state().opened : open });
    }

    // istanbul ignore next
    public setFlags(updatedFlags: string[]): void {
        this.setState({ navigationFlags: updatedFlags });
    }
}
