// Angular Imports
import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

// Third Party Import
import { Observable } from 'rxjs';

// Project Imports
import { SharedModule } from '@shared/shared.module';
import { AspenToastService, IToastMessage } from './aspen-toast.service';

@Component({
    standalone: true,
    selector: 'aspen-toast',
    templateUrl: './aspen-toast.component.html',
    styleUrls: ['./aspen-toast.component.scss'],
    imports: [SharedModule]
})
export class AspenToastComponent {
    public countLeft$: Observable<number> = this.aspenToastService.countLeft$;

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public toastMessage: IToastMessage,
        private readonly aspenToastService: AspenToastService,
        private readonly matSnackBar: MatSnackBar
    ) {}

    public onDismiss(): void {
        this.matSnackBar.dismiss();
    }
}
