<div class="aspen-splash__container">
    <span>
        <mat-icon
            class="aspen-splash__icon"
            [inline]="true"
            svgIcon="vizio:aspen-filled"
        ></mat-icon>
    </span>

    <span class="aspen-splash__name-container">
        <mat-icon
            class="aspen-splash__name"
            [inline]="true"
            svgIcon="vizio:aspen-name-filled"
        ></mat-icon>
    </span>
    <span>
        <img
            alt="VIZIO Logo"
            class="aspen-splash__vizio-logo"
            src="../../../../assets/images/logo/VIZIO_LOGO_WHITE.png"
        />
    </span>
</div>
