// Angular Imports
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Third Party Imports
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

// Project Imports
import { AspenUserService } from '@shared/aspen-user/services/user.service';
import { FeatureFlagViewModel } from '../feature-flags.types';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagsService {
    public static readonly totalFeatureFlags = [
        'bulk-update-tool',
        'pod-building',
        '1st-slot-performance',
        'supply-analytics'
    ].sort();

    public userFeatureFlags: Observable<string[]> = this.aspenUserService.user$.pipe(
        map((user) => user.featureFlags)
    );

    constructor(
        private aspenUserService: AspenUserService,
        private httpClient: HttpClient
    ) {}

    public getAllFeatureFlags(): Observable<FeatureFlagViewModel[]> {
        return of(FeatureFlagsService.totalFeatureFlags).pipe(
            withLatestFrom(this.userFeatureFlags),
            map(([flags, userFlags]): FeatureFlagViewModel[] => {
                return flags.map((flagName) => {
                    return { [`${flagName}`]: userFlags?.includes(flagName) };
                });
            })
        );
    }

    public getAllAutoGenFlags(): Observable<string[]> {
        return this.userFeatureFlags.pipe(
            map((flags) => {
                return flags.filter(
                    (flag) =>
                        !FeatureFlagsService.totalFeatureFlags.includes(flag) &&
                        (flag.includes('--maintenance') || flag.includes('--nav-disabled'))
                );
            })
        );
    }

    public updateFlag(updatedFlag: FeatureFlagViewModel): Observable<FeatureFlagViewModel> {
        return this.httpClient
            .put(`@user-api/v1/feature-flags`, updatedFlag)
            .pipe(map(() => updatedFlag));
    }
}
