import { AspenNavigationModule, AspenNavItem } from '@core/navigation/aspen-navigation.module';
import { NgModule } from '@angular/core';

const navItems: AspenNavItem[] = [
    {
        id: 'supply-hub',
        label: 'Supply Hub',
        parent: 'publisher-tools',
        order: 10,
        type: 'basic',
        link: '/supply-hub',
        dataCy: 'sidebarItemSupplyHub',
        requiredPermissions: ['onboarding-tools.read']
    },
    {
        id: 'bulk-update-tool',
        label: 'Bulk Update Tool',
        parent: 'publisher-tools',
        order: 20,
        type: 'basic',
        link: '/bulk-update-tool',
        dataCy: 'sidebarItemBulkUpdateTool',
        requiredFeatureFlags: ['bulk-update-tool']
    },
    {
        id: 'supply-config',
        label: 'Supply Config',
        parent: 'publisher-tools',
        order: 30,
        type: 'basic',
        link: '/supply-config',
        dataCy: 'sidebarItemSupplyConfig',
        requiredPermissions: ['onboarding-tools.read']
    },
    {
        id: 'macro-manager',
        label: 'Macro Manager',
        parent: 'publisher-tools',
        order: 40,
        type: 'basic',
        link: '/macro-manager',
        dataCy: 'sidebarItemMacro',
        requiredFeatureFlags: [],
        requiredPermissions: ['macro-manager.read', 'macro-manager.create']
    },
    {
        id: 'vail-ad-tag-requests',
        label: 'Vail Ad Tag Requests',
        parent: 'publisher-tools',
        order: 50,
        type: 'basic',
        dataCy: 'sidebarItemVailAdTagRequests',
        requiredPermissions: ['onboarding-tools.read'],
        link: '/vail-ad-tag-requests'
    }
];

@NgModule({
    imports: [AspenNavigationModule.forChild(navItems)]
})
export class PublisherToolsNavigationModule {}
