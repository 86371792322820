// Angular Imports
import { Injectable } from '@angular/core';

// Third Party Imports
import { Observable, map } from 'rxjs';
import { getMonth } from 'date-fns';

// Project Imports
import { StateServiceBase } from '@core/state-service/state-service.base';

export interface IEasterEggState {
    isSpooky: boolean;
    iconClicks: number;
}

const initialState: IEasterEggState = {
    isSpooky: false,
    iconClicks: 0
};

@Injectable({
    providedIn: 'root'
})
export class EasterEggService extends StateServiceBase<IEasterEggState> {
    public isSpooky$: Observable<boolean> = this.select((state) => state.iconClicks).pipe(
        map((iconClicks) => {
            return iconClicks >= 3 && getMonth(new Date()) === 9;
        })
    );

    constructor() {
        super(initialState);
    }

    public setIsSpooky(isSpooky: boolean) {
        this.setState({ isSpooky });
    }

    public incrementIconClicks() {
        const currentIconClicks = this.getStateValue('iconClicks');
        const iconClicks = currentIconClicks === 3 ? 0 : currentIconClicks + 1;

        this.setState({ iconClicks });
    }
}
