import { Component, computed, OnDestroy, OnInit, Signal, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { debounceTime, fromEvent, Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { EasterEggService } from '@shared/easter-eggs/easter-egg.service';
import {
    AspenNavigationStateService,
    NavigationConfig
} from '@core/navigation/aspen-navigation-state.service';
import { Router } from '@angular/router';

@Component({
    selector: 'aspen-side-nav',
    templateUrl: './aspen-side-nav.component.html',
    styleUrls: ['./aspen-side-nav.component.scss']
})
export class AspenSideNavComponent implements OnInit, OnDestroy {
    @ViewChild('drawer') public sidenav: MatSidenav;
    public resizeObservable$: Observable<Event>;
    public resizeSubscription$: Subscription;
    private unsubscribeAllSubject: Subject<void> = new Subject();
    public unsubscribeAll$: Observable<void> = this.unsubscribeAllSubject.asObservable();
    public navConfig: Signal<NavigationConfig[]> = this.navStateService.navConfig;

    public openedSignal = computed(() => {
        return this.navConfig().find((item) => {
            return item.children.some((child) => {
                return this.router.url.includes(child.link);
            });
        })?.id;
    });
    public showNav: Signal<boolean> = this.navStateService.opened;

    constructor(
        private easterEggService: EasterEggService,
        private navStateService: AspenNavigationStateService,
        private router: Router
    ) {}

    public mobile: boolean = false;

    public isSpooky$: Observable<boolean> = this.easterEggService.isSpooky$;

    // istanbul ignore next
    public ngOnInit(): void {
        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$
            .pipe(debounceTime(500), takeUntil(this.unsubscribeAll$))
            .subscribe((evt) => {
                this.setOpened(evt);
            });
        if (window.innerWidth < 768) {
            this.navStateService.toggleNav(false);
            this.mobile = true;
        } else {
            this.navStateService.toggleNav(true);
            this.mobile = false;
        }
    }

    // mocking this event is proving to be difficult
    // istanbul ignore next
    public setOpened(evt: Event): void {
        const { innerWidth } = evt.currentTarget as Window;
        if (innerWidth < 768) {
            this.navStateService.toggleNav(false);
            this.mobile = true;
        } else {
            this.navStateService.toggleNav(true);
            this.mobile = false;
        }
    }

    // unsubscribe all is private
    // istanbul ignore next
    public ngOnDestroy(): void {
        this.unsubscribeAllSubject.next();
    }

    public toggleNav(): void {
        this.navStateService.toggleNav();
    }

    public iconClicked(): void {
        this.easterEggService.incrementIconClicks();
    }
}
