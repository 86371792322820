// Project Imports
import { AspenParentNavItem } from './aspen-navigation.module';

export type AspenParentId =
    | 'publisher-performance'
    | 'planning'
    | 'campaigns'
    | 'vizio-dmp'
    | 'publisher-tools'
    | 'dev-tools'
    | 'user';

export const aspenNavStructure: AspenParentNavItem[] = [
    {
        id: 'publisher-performance',
        label: 'Publisher Performance',
        icon: 'heroicons_outline:chart-bar',
        requiredPermissions: ['dashboards.read'],
        dataCy: 'sidebarGroupBusiness'
    },
    {
        id: 'planning',
        label: 'Planning',
        icon: 'heroicons_outline:clipboard-check',
        requiredPermissions: ['reservations.read', 'user-configs.read'],
        dataCy: 'sidebarGroupPlanning'
    },
    {
        id: 'campaigns',
        label: 'Campaigns',
        icon: 'heroicons_outline:speakerphone',
        dataCy: 'sidebarGroupCampaigns',
        requiredPermissions: ['campaigns.read']
    },
    {
        id: 'vizio-dmp',
        label: 'VIZIO DMP',
        icon: 'heroicons_outline:user-group',
        dataCy: 'sidebarGroupVizioDmp'
    },
    {
        id: 'publisher-tools',
        label: 'Publisher Tools',
        icon: 'heroicons_outline:presentation-chart-line',
        dataCy: 'sidebarGroupOnboarding',
        requiredPermissions: ['onboarding-tools.read']
    },
    {
        id: 'dev-tools',
        label: 'Developer Tools',
        icon: 'heroicons_outline:code',
        dataCy: 'sidebarGroupDeveloper',
        requiredPermissions: ['devtools.read']
    }
];
