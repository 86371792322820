// Angular Imports
import { Injectable } from '@angular/core';

// Third Party Imports
import { BehaviorSubject, Observable } from 'rxjs';

const LOCALSTORAGE_JWT_KEY = 'aspen-jwt';

@Injectable({
    providedIn: 'root'
})
export class AspenAuthService {
    private readonly jwtSubject: BehaviorSubject<string> = new BehaviorSubject(
        localStorage.getItem(LOCALSTORAGE_JWT_KEY)
    );

    public jwt$: Observable<string> = this.jwtSubject.asObservable();

    public setJwt(value: string): void {
        localStorage.setItem(LOCALSTORAGE_JWT_KEY, `${value}`);
        this.jwtSubject.next(`${value}`);
    }

    public clearJwt(): void {
        localStorage.removeItem(LOCALSTORAGE_JWT_KEY);
        this.jwtSubject.next('');
    }
}
