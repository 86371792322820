// Angular Imports
import { NgModule } from '@angular/core';

// Project Imports
import { MapToValuesPipe } from './map-to-values.pipe';
import { PrettifyJsonPipe } from '@shared/pipes/prettify-json.pipe';
import { NumberSuffixPipe } from '@shared/pipes/number-suffix.pipe';
import { TypeOfPipe } from './type-of.pipe';
import { CamelCaseToTitleCasePipe } from './camel-case-to-title-case.pipe';
import { CurrencyAbbreviation } from './currency-abbreviation.pipe';
import { AspenFilterPipe } from '@shared/pipes/aspen-filter.pipe';
import { RefreshCadence } from './refresh-cadence.pipe';
import { HighlightPipe } from './highlight.pipe';
import { XmlPipe } from './xml.pipe';

@NgModule({
    declarations: [
        MapToValuesPipe,
        PrettifyJsonPipe,
        NumberSuffixPipe,
        TypeOfPipe,
        CamelCaseToTitleCasePipe,
        CurrencyAbbreviation,
        AspenFilterPipe,
        RefreshCadence,
        HighlightPipe,
        XmlPipe
    ],
    exports: [
        MapToValuesPipe,
        PrettifyJsonPipe,
        NumberSuffixPipe,
        TypeOfPipe,
        CamelCaseToTitleCasePipe,
        CurrencyAbbreviation,
        AspenFilterPipe,
        RefreshCadence,
        HighlightPipe,
        XmlPipe
    ]
})
export class PipesModule {}
