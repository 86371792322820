import { AspenNavigationModule, AspenNavItem } from '@core/navigation/aspen-navigation.module';
import { NgModule } from '@angular/core';

const navItems: AspenNavItem[] = [
    {
        id: 'pacing-reporting',
        label: 'Pacing Reporting',
        parent: 'campaigns',
        order: 10,
        type: 'basic',
        link: '/pacing-reporting',
        dataCy: 'sidebarItemPacing',
        requiredPermissions: ['campaigns.read']
    }
];

@NgModule({
    imports: [AspenNavigationModule.forChild(navItems)]
})
export class CampaignsNavigationModule {}
