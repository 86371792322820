import { IEnvironment } from '@core/environment/environment.types';

export const environment: IEnvironment = {
    production: true,

    userApiUrl: 'https://aspen-staging.vizio.com/api',

    dataApiUrl: 'https://data.aspen-staging.vizio.com/data',

    ssoUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',

    clientId: '8208bd73-b995-47ad-ae13-0c88206413d9',

    redirectUri: 'https://redirect.atiaspen.com',

    scope: 'openid offline_access https://graph.microsoft.com/User.Read',

    googleAnalyticsId: 'G-L971XF9DH8',

    segmentRefreshEnabled: false,

    meadUrl: 'https://mead-stage.internal.atiads-stage.tv',

    meadToken:
        'eyJhbGciOiJub25lIn0.eyJpZCI6IjFMTUdldFFTazlINGh3PT0iLCJzZWNyZXQiOiJYSUwvTk5rMDBST2hTdHlKTTk2VGtwbm1TcXV0MWJHMnhmRWR6R0RyM0RRPSJ9'
};
