// Angular Imports
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';

// Third Party Imports
import { Observable, of, catchError, switchMap, first } from 'rxjs';

// Project Imports
import { AspenAuthService } from '../aspen-auth.service';

@Injectable({
    providedIn: 'root'
})
export class AspenSSOGuard {
    constructor(
        private authService: AspenAuthService,
        private httpClient: HttpClient,
        private router: Router
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> {
        if (window.location.hostname === 'localhost') {
            const queryParamKeys = Object.keys(route.queryParams);
            const queryParams = queryParamKeys.map((x) => `${x}=${route.queryParams[x]}`).join('&');

            // @TODO Probably wanna env config this at some point
            window.location.href = `http://aspen.vizio.local:4200/auth/response-oidc?${queryParams}`;
        }

        return this.httpClient
            .post('@user-api/login', {
                code: route.queryParams.code
            })
            .pipe(
                first(),
                switchMap((response) => {
                    const ssoState = JSON.parse(decodeURI(route.queryParams.state));

                    this.authService.setJwt(response['aspen-jwt']);

                    this.router.navigate([ssoState.redirectUri]);

                    return of(true);
                }),
                catchError((error) => {
                    this.router.navigate(['error']);

                    return of(true);
                })
            );
    }
}
