// Angular Imports
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

// Third Party Imports
import { first } from 'rxjs';

// Project Imports
import { AspenUserService } from '@shared/aspen-user/services/user.service';

@Directive({
    selector: '[showWithFeatureFlag]'
})
export class FeatureFlagShowDirective {
    private _hasView: boolean = false;

    constructor(
        private _templateRef: TemplateRef<any>,
        private _viewContainerRef: ViewContainerRef,
        private _aspenUserService: AspenUserService
    ) {}

    @Input() set showWithFeatureFlag(featureFlags: string | string[]) {
        this._aspenUserService
            .userHasFeatureFlags(featureFlags)
            .pipe(first())
            .subscribe({
                next: (hasFeatureFlag) => {
                    if (hasFeatureFlag && !this._hasView) {
                        this._viewContainerRef.createEmbeddedView(this._templateRef);
                        this._hasView = true;
                    } else if (!hasFeatureFlag && this._hasView) {
                        this._viewContainerRef.clear();
                        this._hasView = false;
                    }
                }
            });
    }
}
