import { BaseRouteReuseStrategy, ActivatedRouteSnapshot } from '@angular/router';

export class AppReuseStrategy extends BaseRouteReuseStrategy {
    constructor() {
        super();
    }

    public shouldReuseRoute(
        future: ActivatedRouteSnapshot,
        current: ActivatedRouteSnapshot
    ): boolean {
        return false;
    }
}
